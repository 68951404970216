<template>
    <div class="ml-1">
        <div class="fl-te-c">
            <h4 class="text-primary">Products</h4>
            <div class="fl-x-tr">
                <validated-input class="mr-1" v-model="search" placeholder="Search">
                    <template #append>
                        <i class="fa fa-search"></i>
                    </template>
                </validated-input>
                <div class="btn-group mt-1">
                    <drop-down-button icon="fa fa-filter" color="primary" class="sm" dropdown-width="15r">
                        <div class="row p-3">
                            <div class="col-md-12">
                                <validated-ajax-vue-select label="Category" :url="categoryOptions" name="Category"
                                                           v-model="model.category"
                                                           :disabled="loading" @input="filterData(model.category)"/>
                            </div>
                            <div class="col-md-12">
                                <validated-vue-select label="Price" name="Price" :options="sortOptions" v-model="sort"
                                                      :disabled="loading" @input="loadData"/>
                            </div>
                        </div>
                    </drop-down-button>

                    <btn @click="openModal('add')" color="primary" size="sm" text="" icon="fa fa-plus"/>
                    <btn @click="OpenExcelModal" text="Import" size="sm"></btn>
                </div>
            </div>
        </div>
        <div>
            <infinite-scroll-container @load-next="loadNext" class="row">
                <product-card v-for="(product, index) in products" :key="index" :product="product"
                              @EditItem="EditProduct(product)" @update="setUpdate">
                </product-card>
            </infinite-scroll-container>
        </div>
        <modal title="Import Excel" @hidden="model1={}" ref="excelModal" :no-close-on-backdrop="true" width="30r">
            <s-form role="form" @submit="uploadExcel">
                <div class="col-md-12">
                    <validated-file-input name="File" label="File" v-model="model1.file"/>
                </div>
                <div class="col-md-12">
                    <div class="fl-x fl-j-c">
                        <btn type="submit" class="px-5" loading-text="uploading..." :loading="uploading">
                            Upload
                        </btn>
                    </div>
                </div>
            </s-form>
        </modal>
        <modal title="Product" @hidden="model={}" ref="modal" width="30r">
            <s-form role="form" @submit="AddProduct">
                <div class="row">
                    <!--                    <div class="col-md-4">-->
                    <!--                        <img width="200" height="200" v-bind:src="'/upload/' + model.image"/>-->
                    <!--                    </div>-->

                    <div class="col-md-12">
                        <validated-input name="name" label="Name"
                                         v-model="model.name"/>
                    </div>
                    <div class="col-md-12">
                        <validated-file-input name="Image" label="Image" v-model="model.image"/>
                    </div>
                    <div class="col-md-12">
                        <validated-ajax-vue-select label="Category" :url="categoryOptions" name="Category"
                                                   v-model="model.category"
                                                   :disabled="loading"/>
                    </div>
                    <div class="col-md-12">
                        <validated-textarea name="description" label="Description"
                                            v-model="model.description"/>
                    </div>
                    <div class="col-md-6">
                        <validated-input name="list_price" label="List price"
                                         v-model="model.list_price"/>
                    </div>
                    <div class="col-md-6">
                        <validated-input name="list_price" label="Selling price"
                                         v-model="model.selling_price"/>
                    </div>
                    <div class="col-md-6">
                        <validated-checkbox label="New Product" v-model="model.label"></validated-checkbox>
                    </div>
                    <div class="col-md-12">
                        <div class="fl-x fl-j-c">
                            <btn type="submit" class="px-5" loading-text="saving..." :loading="saving">
                                Save
                            </btn>
                        </div>
                    </div>

                </div>
            </s-form>
        </modal>
    </div>
</template>
<script>
import ProductCard from '@/views/catalog/catalogComponents/productCard';
import axios from 'secure-axios';
import urls from '../../../data/urls';
import InfiniteScrollContainer from '@components/InfiniteScroll';
import debounce from 'lodash.debounce';

export default {
    name  : 'productListing',
    props : {
        firstProduct : {
            type     : Array,
            required : false
        },
        category_id : {
            type     : Number,
            required : false
        }
    },
    components : {
        InfiniteScrollContainer,
        ProductCard
    },
    data () {
        return {
            categoryOptions : urls.select.CategorySelect,
            products        : [],
            page            : 0,
            isLast          : false,
            loading         : false,
            uploading       : false,
            saving          : false,
            search          : '',
            sort            : '',
            sortOptions     : [
                {
                    value : 'desc',
                    label : 'high to low'
                },
                {
                    value : 'asc',
                    label : 'low to high'
                }
            ],
            model : {
                name          : '',
                image         : [],
                description   : '',
                list_price    : '',
                selling_price : '',
                category      : this.category_id,
                sellable      : true,
                enabled       : true,
                label         : true,
                label_color   : 'primary'
            },
            model1 : {
                file : null
            }
        };
    },
    mounted () {
        this.products = [...this.firstProduct];
    },
    watch : {
        search : debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500),

        category_id : debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500),

        firstProduct () {
            this.products = [...this.firstProduct];
        }
    },
    methods : {
        filterData (item) {
            this.$emit('filter', item);
        },
        setUpdate () {
            // this.$emit('update');
            this.loadData();
        },
        async loadNext () {
            if (!this.category_id) {
                return;
            }

            if (this.loading) {
                return;
            }

            if (this.isLast) {
                return;
            }

            this.page += 1;
            await this.loadData();
        },
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.products.list, {
                params : {
                    search     : this.search,
                    id         : this.category_id,
                    sort_price : this.sort,
                    page       : this.page,
                    per_page   : 20
                }
            });
            this.loading = false;
            this.setData(response);
        },
        setData (response) {
            if (response.data) {
                if (response.data.data.length > 0) {
                    this.products = response.data.data;
                } else {

                }
            }
        },
        OpenExcelModal () {
            this.$refs.excelModal.show();
        },

        openModal (item) {
            // this.$refs.modal.show();
            if (item === 'add') {
                this.model.id = '';
                this.model.name = '';
                this.model.description = '';
                this.model.list_price = '';
                this.model.selling_price = '';
                this.$refs.modal.show();
            } else {
                this.$refs.modal.show();
            }
        },
        async uploadExcel () {
            this.uploading = true;
            const UploadUrl = urls.products.importExcel;
            const response = await axios.form(UploadUrl, this.model1);
            const json = response.data;
            if (json.success === true) {
                this.$notify('Excel Upload Successfully', 'Success', {
                    type : 'success'
                });
            }
            this.$refs.excelModal.close();
        },
        async AddProduct () {
            if (this.model.id) {
                this.saving = true;
                const EditUrl = urls.products.edit;
                const response = await axios.form(EditUrl, this.model);
                console.log('Edit URL');
                console.log('response', response.data);

                const itemID = response.data.data.id;

                const myArr = this.products;
                const index = myArr.map(function (item) {
                    return item.id;
                }).indexOf(itemID);

                myArr.splice(index, 1);

                this.products.push(response.data.data);
                const json = response.data;
                if (json.success === true) {
                    this.$notify('Product Edited Successfully', 'Success', {
                        type : 'success'
                    });
                }
                this.saving = false;
            } else {
                this.saving = true;
                const addUrl = urls.products.add;
                const response = await axios.form(addUrl, this.model);
                console.log('response: ', response.data);
                this.products.push(response.data.data);
                console.log('Add URL');
                const json = response.data;
                if (json.success === true) {
                    this.$notify('Product Added Successfully', 'Success', {
                        type : 'success'
                    });
                }
                this.saving = false;
            }

            this.$refs.modal.close();
        },
        async EditProduct (item) {
            console.log('item:', item);
            this.model = {
                id            : item.id,
                name          : item.name,
                description   : item.description,
                list_price    : item.list_price,
                selling_price : item.selling_price,
                category      : this.category_id,
                sellable      : item.sellable,
                label         : item.label === 'false' ? '' : 'New',
                label_color   : item.label_color,
                image         : item.image
            };
            this.openModal('edit');
        }

    }
};
</script>

<style scoped>
</style>
