<template>
    <div class="fl-te-c py-2 bb-1 p-2 cursor-pointer" >
        <div class="fl-x fl-a-c" @click="$emit('click')">

            <img style="" class="categ-img" src="../../../assets/img/No_image_available.svg"
                 :alt="category.name"
                 v-if="!category.image"/>
            <img style="height:35px;" :src="'/upload/'+category.image" alt="Image" class="round-1 categ-img" v-else>

            <span class="ml-1 m-0 font-weight-medium">{{ category.name }}</span>
        </div>

        <div class="btn-group">

            <btn color="danger" size="xs" design="" icon="fa fa-check" style="color: var(--color-danger) !important;" text="" v-if="category.enabled===false"
                 @click="categorySwitch(category)"/>
            <btn color="primary" size="xs" design="" style="color: var(--color-primary) !important;"  icon="fa fa-check" text="" v-else
                 @click="categorySwitch(category)"/>
            <btn @click="EditItem" color="primary" size="xs" class="border-round" icon="fa fa-pencil" text=""></btn>
            <btn icon="fa fa-trash" size="xs" color="danger" @click="categoryDelete(category.id)"></btn>

        </div>

        <!--        Dialog-Modal-Start          -->
        <!--        -->
        <!--        -->
        <enable-modal dialog title="Enable" ref="enableModal">
            Are you sure ?
        </enable-modal>

        <disable-modal dialog title="Disable" ref="disableModal">
            Are you sure ?
        </disable-modal>
        <!--        Modal-End           -->
        <!--        -->
        <!--        -->

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
            <p>You are about to delete the product. Are you sure?</p>
            <template #loading>
                <loading-animation/>
                Please wait while we delete the Product.
            </template>
        </delete-modal>
    </div>

</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import EnableModal from '@components/EnableModal';
import DisableModal from '@components/DisableModal';

export default {
    name       : 'nameCard',
    components : { EnableModal, DisableModal },
    props      : {
        category : { type : Object },
        active   : {
            type    : Boolean,
            default : false
        }
    },
    data () {
        return {
            deleteUrl    : urls.categories.delete,
            deletingItem : {
                id : ''
            }
        };
    },
    methods : {
        EditItem () {
            this.$emit('EditItem');
        },
        async categorySwitch (item) {
            console.log('item', item);
            let modal;
            if (item.enabled) {
                modal = this.$refs.disableModal;
            } else {
                modal = this.$refs.enableModal;
            }

            const response = await modal.show();
            if (response === false) {
                return;
            }
            if (item.enabled) {
                this.$set(item, 'enabled', false);
            } else {
                this.$set(item, 'enabled', true);
            }
            const url = urls.categories.enableDisable;
            axios.form(url, {
                id      : item.id,
                enabled : item.enabled // edited
            });
        },
        categoryDelete (catId) {
            this.deletingItem.id = catId;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            // this.$router.go();
            this.$emit('update');
        }
    }
};
</script>

<style scoped>
.categ-img {
    width: 35px !important;
    height: auto;
    margin-right: var(--spacer-2);
}
</style>
