<template>
    <div class="col-md-3">
        <div class="card bs-4 mb-4">
            <div style="min-height: 180px; background-position: center center; background-size:cover;" :style="image"
                 class="round-2 br-1 bs-1" :class="product.enabled  ? '' : 'img-disable'">
                <div class="fl-x fl-j-sb p-2">
                    <div class="btn-group p-0">

                        <!--                    <edit-icon-button @click="EditItem()"/>-->
                        <!--                    <enable-button v-if="product.enabled ==='0'" @click="productSwitch(product)"/>-->
                        <!--                    <disable-button v-else @click="productSwitch(product)"/>-->

                        <!--                        <btn color="primary" size="sm" design="" icon="fa fa-check"-->
                        <!--                             style="color: var(&#45;&#45;color-primary) !important;" text="" v-if="product.enabled ==='0'"-->
                        <!--                             @click="productSwitch(product)"/>-->
                        <!--                        <btn color="primary" size="sm" design="" style="color: var(&#45;&#45;color-primary) !important;"-->
                        <!--                             icon="fa fa-check" text="" v-else-->
                        <!--                             @click="productSwitch(product)"/>-->
                        <!--                        <btn color="danger" size="sm" icon="fa fa-stop" v-if="product.sellable ==='0'"-->
                        <!--                             @click="stockSwitch(product)"></btn>-->
                        <!--                        <btn color="danger" size="sm" icon="fa fa-stop" v-else @click="stockSwitch(product)"/>-->
                        <!--                    <enable-button v-if="product.sellable ==='0'" @click="stockSwitch(product)"/>-->
                        <!--                    <disable-button v-else @click="stockSwitch(product)"/>-->
                        <btn color="primary" size="sm" icon="fa fa-pencil" text="" @click="EditItem()"/>
                    </div>
                    <div v-if="product.label==='true'">

                        <btn text="New" size="sm"></btn>
                    </div>
                </div>

            </div>
            <div class="fl-x fl-te-c pr-2">
                <btn color="primary" design="" class="on-off-btn btn-content-animate c-btn text-primary"
                     icon="fa fa-toggle-on fa-2x"
                     text="Enabled"
                     key="on" @click="productSwitch(product)" v-if="product.enabled===true"/>
                <btn color="primary" design="" class="on-off-btn c-btn btn-content-animate c-btn color-gray"
                     icon="fa fa-toggle-on fa-2x"
                     text="Disabled"
                     key="off" @click="productSwitch(product)" v-else/>
                <btn icon="fa fa-trash" size="xs" color="danger" @click="productDelete(product.id)"></btn>
            </div>

            <h5 class="px-1"> {{ product.name }} </h5>
            <p class="m-0 px-1">
                <span class="mr-3">&#8377;{{ product.selling_price }}</span>
                <strike>&#8377;{{ product.list_price }} </strike>
            </p>
        </div>
        <enable-modal dialog title="Enable Product" ref="enableModal">
            Are you sure ?
        </enable-modal>

        <disable-modal dialog title="Disable Product" ref="disableModal">
            Are you sure ?
        </disable-modal>

        <enable-modal dialog title="Enable Stock" ref="EnableStockModal">
            Are you sure ?
        </enable-modal>

        <disable-modal dialog title="Disable Stock" ref="DisableStockModal">
            Are you sure ?
        </disable-modal>
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
            <p>You are about to delete the product. Are you sure?</p>
            <template #loading>
                <loading-animation/>
                Please wait while we delete the Product.
            </template>
        </delete-modal>

    </div>

</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import EnableModal from '@components/EnableModal';
import DisableModal from '@components/DisableModal';

export default {
    name  : 'productCard',
    props : {
        product : {
            type     : Object,
            required : true
        },
        active : {
            type    : Boolean,
            default : false
        }
    },
    components : {
        EnableModal,
        DisableModal
    },
    data () {
        return {
            loading      : false,
            deleteUrl    : urls.products.delete,
            deletingItem : {
                id : ''
            }
        };
    },
    computed : {
        image () {
            return {
                backgroundImage : 'url(' + 'https://microcommerce.alocart.in/upload/' + this.product.image + ')'
            };
        }
    },

    methods : {
        EditItem () {
            this.$emit('EditItem');
        },
        async productSwitch (item) {
            console.log('active', this.active);
            console.log('item', item);
            let modal;
            if (item.enabled) {
                modal = this.$refs.disableModal;
            } else {
                modal = this.$refs.enableModal;
            }

            const response = await modal.show();
            if (response === false) {
                return;
            }
            if (item.enabled) {
                this.$set(item, 'enabled', false);
            } else {
                this.$set(item, 'enabled', true);
            }
            const url = urls.products.enableDisable;
            axios.form(url, {
                id      : item.id,
                enabled : item.enabled
            });
        },
        productDelete (product) {
            this.deletingItem.id = product;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';

            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            // this.$router.go();
            this.$emit('update');
        },
        async stockSwitch (item) {
            console.log('item', item);

            let modal;
            if (item.enabled === '1') {
                modal = this.$refs.DisableStockModal;
            } else {
                modal = this.$refs.EnableStockModal;
            }

            const response = await modal.show();
            if (response === false) {
                return;
            }

            const url = urls.products.stockEnableDisable;
            axios.form(url, {
                id : item.id
            });
            if (item.enabled === '1') {
                this.$set(item, 'sellable', '0');
                this.active = false;
            } else {
                this.$set(item, 'sellable', '1');
                this.active = true;
            }
        }

    }
};
</script>

<style scoped>

</style>
