import Modal from 'lego-framework/src/containers/Modal';

export default {
    extends : Modal,
    name    : 'enable-modal',
    props   : {
        width             : { type : String, default : '25r' },
        dialog            : { type : Boolean, default : true },
        headerColor       : { type : String, default : 'primary' },
        bodyColor         : { type : String, default : 'primary' },
        okButtonColor     : { type : String, default : 'primary' },
        okButton          : { type : String, default : 'Enable' },
        cancelButtonColor : { type : String, default : 'info' }
    }
};
