<template>
    <div>
        <div class="card text-center" v-if="loading">
            <loading-animation/>
            <h6 class="pl-2">Please wait while data is being loaded...</h6>
        </div>
        <div v-else class="row">
            <div class="col-md-2 br-1 p-0">
                <div class="fl-te-c p-2">
                    <h4 class="text-primary">Categories</h4>
                    <btn @click="openModal('add')" color="primary" size="sm" design="basic-b" icon="fa fa-plus"
                         text=""></btn>
                </div>
                <validated-input class="mt-2 p-2" v-model="search"></validated-input>

                <div>
                    <div v-for="(category, index) in categories" v-bind:key="index" class="hover-bg-3"
                         :class="{'active':activeCategoryIdx===index}">
                        <name-card :category="category" @EditItem="EditCategory(category)"
                                   @click="FilterCat(category.id,index)" @update="setUpdate"></name-card>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="card text-center" v-if="loadingProducts">
                    <loading-animation/>
                    <h6 class="pl-2">Please wait while data is being loaded...</h6>
                </div>
                <div v-else>
                    <product-listing v-if="products.data.length > 0" :category_id="category_id"
                                     :firstProduct="products.data" @filter="filterData" @update="setUpdate"></product-listing>
                    <div class="fl-x-cc min-height-100" v-else>
                        <div class="bg-info p-3 center-text">
                            NO PRODUCTS AVAILABLE....
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal title="Category" @hidden="model={}" ref="catModal" width="30r">
            <s-form role="form" @submit="AddCategory">
                <div class="row">
                    <div class="col-md-12">
                        <validated-input name="name" label="name"
                                         v-model="model.name"/>
                    </div>
                    <div class="col-md-12">
                        <validated-file-input name="Image" label="Image" v-model="model.image"/>
                    </div>
                    <div class="col-md-12">
                        <div class="fl-x fl-j-c">
                            <btn type="submit" class="px-5">
                                Save
                            </btn>
                        </div>

                    </div>

                </div>
            </s-form>
        </modal>
    </div>
</template>

<script>
import ProductListing from '@/views/catalog/catalogComponents/productListing';
import NameCard from '@/views/catalog/catalogComponents/nameCard';
import axios from 'secure-axios';
import urls from '@/data/urls';
import debounce from 'lodash.debounce';

export default {
    name       : 'catalog',
    components : {
        NameCard,
        ProductListing
    },
    data () {
        return {
            activeCategoryIdx : 0,
            categories        : [],
            products          : [],
            category_id       : 1,
            page              : 1,
            isLast            : false,
            loading           : false,
            loadingProducts   : false,
            search            : '',
            model             : {
                name  : '',
                image : []

            }
        };
    },
    watch : {
        search : debounce(function () {
            this.categories = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },
    beforeMount () {
        this.loadFirstData();
    },
    methods : {
        async FilterCat (item, index) {
            this.loading = false;
            this.category_id = item;
            console.log('item', item);
            this.activeCategoryIdx = index;
            this.loadingProducts = true;
            const response = await axios.get(urls.products.list, {
                params : {
                    search   : this.search,
                    id       : this.category_id,
                    page     : this.page,
                    per_page : 20
                }
            });
            this.loading = false;
            this.loadingProducts = false;
            if (response.data) {
                console.log('if (response.data) - Product Listing', response);
                this.products = response.data;
            }
        },
        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },
        async loadData () {
            this.loading = false;
            const response = await axios.get(urls.categories.list, {
                params : {
                    show_first : true,
                    search     : this.search,
                    id         : 1,
                    page       : this.page,
                    per_page   : 20
                }
            });
            this.categories = response.data.data;
            this.loading = false;
            //  this.setData(response); // edited
        },
        filterData (item) {
            const index = this.categories.findIndex(el => el.id === item);
            this.FilterCat(item, index);
        },
        async loadFirstData () {
            this.loadingProducts = true;
            this.loading = true;
            const response = await axios.get(urls.categories.list, {
                params : {
                    search     : this.search,
                    show_first : true
                }
            });
            this.loading = false;
            this.loadingProducts = false;
            this.setData(response);
        },
        setData (response) {
            if (response.data) {
                this.categories = response.data.data;
                this.products = response.data.products;
            }
        },
        setUpdate () {
            this.loadFirstData();
        },
        openModal (item) {
            if (item === 'add') {
                this.model.id = '';
                this.model.name = '';
                this.model.image = '';
                this.$refs.catModal.show();
            } else {
                this.$refs.catModal.show();
            }
        },
        async AddCategory () {
            if (this.model.id) {
                const urlEdit = urls.categories.edit;
                const response = await axios.form(urlEdit, this.model);
                const itemID = response.data.data.id;
                const myArr = this.categories;
                const index = myArr.map(function (item) {
                    return item.id;
                }).indexOf(itemID);

                myArr.splice(index, 1);

                this.categories.push(response.data.data);
                const json = response.data;
                if (json.success === true) {
                    this.$notify('Category Edited Successfully', 'Success', {
                        type : 'success'
                    });
                }
            } else {
                const urlAdd = urls.categories.add;
                console.log('Add URL');
                const response = await axios.form(urlAdd, this.model);
                console.log('response', response.data);
                this.categories.push(response.data.data);
                this.loading = false;
                const json = response.data;
                if (json.success === true) {
                    this.$notify('Category Added Successfully', 'Success', {
                        type : 'success'
                    });
                }
            }

            this.$refs.catModal.close();
        },
        async EditCategory (item) {
            this.model = {
                id   : item.id,
                name : item.name
            };
            this.openModal('edit');
        }

    }
};
</script>

<style scoped>
</style>
